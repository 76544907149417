<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="渠道ID" v-bind="formItemLayout">
        <a-input v-model="form.id" v-decorator="['id']" disabled>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="渠道名称" v-bind="formItemLayout">
        <a-input v-model="form.channelName" v-decorator="['channelName']" placeholder="渠道名称，最多20个字" :max-length="20">
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="二维码" v-bind="formItemLayout">
        <el-image
            style="width: 100px; height: 100px"
            :src="(form.qrCode || '') | fullImgPath"
            fit="contain"
            :preview-src-list="[form.qrCode]"
        />
      </a-form-model-item>
      <a-form-model-item label="备注说明" v-bind="formItemLayout">
        <a-input v-model="form.remark" v-decorator="['remark']" placeholder="备注说明，最多200个字" :max-length="20">
        </a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "ModifyConfigTaskModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
      },
      mode: 'new',
      title: "",
      visible: false,
      confirmLoading: false,
      form: {},
      rules: {
        channelName: [
          {
            required: true,
            message: "渠道名称不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    showModal(record, mode) {
      this.mode = mode || 'new'
      this.visible = true;
      if (this.mode === 'update') {
        this.title = "修改渠道：" + record.channelName;
        this.form = {
          ...record,
        };
      } else {
        this.title = "新增渠道"
        this.form = {
        }
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;
          this.$postJson("adChannel/update", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("保存成功");
            this.$emit("success");
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
